import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import GlobalStyles from "./Styled/global-styles";
import Theme from './Styled/Theme';
import {isIE} from 'react-device-detect';
import Container from './Components/Container';
import Ie from './Components/Ie';
import './react-transitions.css';

class App extends Component{
  render(){
    if (isIE) return <Ie/>
    return (
      <Theme>
        <Router>
          <Container/>
        </Router>
        <GlobalStyles />
      </Theme>
    );
  }
}

export default App;

