import React, { Component } from 'react';
import styled from 'styled-components';
import loading_logo_w from '../Img/loading_logo_w.svg';

class Ie extends Component{
  render(){
    return (
      <Content>
        <div>
          <img src={loading_logo_w} alt={'loading_logo_w'} />
          <p>Interent Explore는<br/>지원하지 않습니다</p>  
          <p>Chrome, Safari, Firefox, Edqe Brower를 이용 해주세요</p>
        </div>
      </Content>
    )
  }
}

const Content = styled.div `
  width: 100vw;
  height: 100vh;
  background-color: #111;
  position: relative;
  text-align: center;
  font-family:'Noto Sans KR';
  margin:0;
  div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img{
      width:28%;
      padding-bottom:50px;
      margin:0;
    }
    p{
      margin:0;
      color:#fff;
    }
    p:nth-child(2){
      font-size: 3em;
      font-weight: bold;
      padding-bottom:20px;
    }
    p:nth-child(3){
      font-size: 1em;
    }
  }
` 

export default Ie;
  
  