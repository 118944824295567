import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    mainYellow: "#F0FF2E",
    mainBlack: "#222",
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);


export default Theme;

