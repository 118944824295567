import React, { Suspense, Component } from 'react';
import {
  Switch,
  Route,
} from "react-router-dom";
import styled from 'styled-components';

// import Home from './Home';
// import LifePlus from './Work/LifePlus';
// import Iyf from './Work/Iyf';
// import Order from './Work/Order';
// import Mobile from './Work/Mobile';
// import Yap from './Work/Yap';
// import Momq from './Work/Momq';
// import Design from './Work/Design';
// import S1 from './Work/S1';
import Loading from './Loading';
import lazyImport from './lazyImport';


const Home = lazyImport('./Home');
const LifePlus = lazyImport('./Work/LifePlus');
const Iyf = lazyImport('./Work/Iyf');
const Order = lazyImport('./Work/Order');
const Mobile = lazyImport('./Work/Mobile');
const Yap = lazyImport('./Work/Yap');
const Momq = lazyImport('./Work/Momq');
const Design = lazyImport('./Work/Design');
const S1 = lazyImport('./Work/S1');


const LoadingMessage = () => (
  <Loading></Loading>
)

const ROUTES = [
  { id:0, path: '/', name: 'Home', Component: Home },
  { id:1, path: '/lifeplus', name: 'LifePlus', Component: LifePlus },
  { id:2, path: '/iyf', name: 'Iyf', Component: Iyf },
  { id:3, path: '/mobile', name: 'Mobile', Component: Mobile },
  { id:4, path: '/order', name: 'Order', Component: Order },
  { id:5, path: '/yap', name: 'Yap', Component: Yap },
  { id:6, path: '/momq', name: 'Momq', Component: Momq },
  { id:7, path: '/design', name: 'Design', Component: Design },
  { id:8, path: '/s1', name: 'S1', Component: S1 },
]

class Container extends Component{
  render(){
  return(
    <Wrapper>
      <Suspense fallback={<LoadingMessage />}>
      <Switch>
        {ROUTES.map(i =>(
          <Route key={i.id} exact path={i.path} component={i.Component} />
        ))}
      </Switch>
      </Suspense>
    </Wrapper>
    );
  } 
}


const Wrapper = styled.div`

`
export default Container;