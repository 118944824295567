import React, { Component } from 'react';
import styled, {keyframes} from 'styled-components';
import circle from '../Img/circle.svg';
import loading_logo from '../Img/loading_logo.svg';

class Loading extends Component{
  render(){
    return(
      <Content>
        <div>
          <img src={circle}  alt={'Jeong JinHyub Portfolio'} />
          <img src={loading_logo} alt={'JH'} />
        </div>
      </Content>
    );
  }
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`;


const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items:center;
    width: 100vw;
    height: 100vh;
  div{
    position: relative;
    img:first-child{
      animation: ${rotate} 10s linear infinite;
      width:20vw;
      height: 20vh;
    }
    img:last-child{
      position: absolute;
      top:7vh;
      left:7vw;
      width:6vw;
      height: 6vh;
    }
  }
`

export default Loading;

